<style type="text/css">
  @import "~@/assets/css/common.css";

  .form-control {
    padding: unset;
    border-radius: unset;
  }

  .form-control input {
    border-radius: unset;
    border: unset;
  }
</style>

<script>
  import $ from "jquery";
  import LoginLeft from "@/views/pages/account/login-left";
  import LoginTop from "@/views/pages/account/login-top";
  import {
    listenEnterEv,
    sendPhoneCode,
    userRegister,
    getcode,
    checkCode
  } from "@/api/index.js";
  export default {
    components: {
      LoginLeft,
      LoginTop,
    },
    data() {
      const checkIdcard = (rule, value, cb) => {
        const regIdcard =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        if (regIdcard.test(value)) {
          return cb();
        }
        cb(new Error("请输入正确的身份证号"));
      };
      const checkEmail = (rule, value, cb) => {
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        if (regEmail.test(value)) {
          return cb();
        }
        cb(new Error("请输入正确的邮箱"));
      };
      const checkMobile = (rule, value, cb) => {
        var regex = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g;
        if (regex.test(value)) {
          return cb();
        }
        cb(new Error("请输入正确的手机号"));
      };
      const checkMm = (rule, value, cb) => {
       var regex=/^[0-9A-Za-z]{6,}$/;
       if (regex.test(value)) {
          return cb();
        }
        cb(new Error("请输入不少于6位数字字母组合"));
      };
      return {
         baseConfig:{},
        verification: "", //验证码
        isShow: false, //验证码窗口
        committingPhoneCode: false,
        formDataUserregister: {
          xm: "",
          sfzjh: "",
          yddh: "",
          dzxx: "",
          mm: "",
          phoneCode: "",
          uuid: "",
          captchaVO: "",
        },
        imgCode: {
          uuid: "",
          img: "",
          code: ""
        },
        rules: {
          xm: [{
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          }, ],
          sfzjh: [{
              required: true,
              message: "请输入身份证号",
              trigger: "blur",
            },
            {
              validator: checkIdcard,
              trigger: "blur",
            },
          ],
          yddh: [{
              required: true,
              message: "请输入手机号",
              trigger: "blur",
            },
            {
              validator: checkMobile,
              trigger: "blur",
            },
          ],
          dzxx: [{
              required: true,
              message: "请输入邮箱地址",
              trigger: "blur",
            },
            {
              // 通过validator 进行自定义校验规则
              validator: checkEmail,
              trigger: "blur",
            },
          ],
          mm: [{
            validator: checkMm,
            trigger: "blur",
          }, ],
          phoneCode: [{
            required: true,
            message: "请输入手机验证码",
            trigger: "blur",
          }, ],
        },
      };
    },
    methods: {
      checkRegister: function() {
        var _this = this
        this.$refs["formDataUserregister"].validate((valid) => {
          if (valid) {
            userRegister(this.formDataUserregister).then(res => {
              if (res.status) {
                _this.$notify.success({
                  title: "成功",
                  message: "注册成功"
                })
                _this.$router.push("/")
              }
            })
          }
        });
      },
      // 获取图片验证码
      getImageCode: function() {
        var _this = this;
        getcode().then((res) => {
          if (res.status) {
            _this.imgCode.uuid = res.data.uuid;
            _this.imgCode.img = res.data.img;
          }
        });
      },
      checkImagecode: function() {
        let uuid = this.imgCode.uuid
        let code = this.verification
        if(code){
          checkCode({uuid:uuid,code:code}).then(res => {
          if(res){
            if(res.status){
              this.getCode()
            }
          }
          })
        }else{
          this.$notify({
            title: "失败",
            message: "请先输入图片验证码",
            type: "error",
          });
          $("#code").focus()
          this.getImageCode()
        }

      },

      /* 发送手机验证码*/
      sendPhoneCode: function(formData) {
        return sendPhoneCode(formData);
      },
      /*获取验证码*/
      getCode: function() {
        var _this = this;
        if (this.verification && !this.committingPhoneCode) {
          /*如果状态正确*/
          var formData = {
            mobilePhone: this.formDataUserregister.yddh,
          };
          this.committingPhoneCode = true;
          this.sendPhoneCode(formData).then((res) => {
            if (res.status) {
              _this.resetCode(60); //倒计时
              _this.$notify({
                title: "成功",
                message: res.message,
                type: "success",
              });
              this.isShow=false
            } else {
              // this.imgCodeChange();
              _this.resetCode(5);
            }
          });
        } else {
          $("#phone").focus();
        }
      },
      //验证手机号码
      checkPhone: function() {
        this.$refs["formDataUserregister"].validateField(
          "yddh",
          (valid) => {
            if (!valid) {
              this.isShow = true;
              this.getImageCode()
            }
          }
        );
      },

      //倒计时
      resetCode: function(second) {
        var timer = null;
        var _this = this;
        timer = setInterval(function() {
          second -= 1;
          if (second > 0) {
            $("#code_button").css("cursor", "no-drop");
            $("#code_button").text(second + "秒");
          } else {
            clearInterval(timer);
            $("#code_button").css("cursor", "pointer");
            $("#code_button").text("发送验证码");
            _this.committingPhoneCode = false;
          }
        }, 1000);
      },
    },
    mounted() {
      listenEnterEv(this.checkRegister);
    },
    created() {
      let conf=JSON.parse(window.localStorage.getItem('BaseConfig'))
      this.baseConfig=conf?conf:{}
    }
  };
</script>

<template>
  <div>
    <div class="login-header">
      <div class="wp">
        <div class="p13">
          <LoginTop />
          <div class="login-bottom mt-4 d-flex">
            <LoginLeft />

            <div class="log-r register">
              <p class="log-tips">用户注册</p>
              <div class="mt-4 login-main">
                <el-form :model="formDataUserregister" :rules="rules" ref="formDataUserregister">
                  <el-form-item prop="xm">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/user.png" alt="" /></span>
                      </div>
                      <el-input id="" type="text" placeholder="请输入您的姓名"
                        aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100"
                        v-model="formDataUserregister.xm" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="sfzjh">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/idcard.png" alt="" /></span>
                      </div>
                      <el-input id="" type="text" placeholder="请输入您的身份证号码"
                        aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100"
                        v-model="formDataUserregister.sfzjh" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="yddh">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/phone.png" alt="" /></span>
                      </div>
                      <el-input id="phone" type="tel" placeholder="请输入手机号码"
                        aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100"
                        v-model="formDataUserregister.yddh" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="dzxx">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/mail.png" alt="" /></span>
                      </div>
                      <el-input id="" type="email" placeholder="请输入电子邮箱地址"
                        aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100"
                        v-model="formDataUserregister.dzxx" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="mm">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/lock.png" alt="" /></span>
                      </div>
                      <el-input id="" type="text" placeholder="请设置登录密码"
                        aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100"
                        v-model="formDataUserregister.mm" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="phoneCode">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/sms.png" alt="" /></span>
                      </div>
                      <el-input id="" type="text" placeholder="请输入短信码"
                        aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100"
                        v-model="formDataUserregister.phoneCode" />
                      <div>
                        <b-button id="code_button" variant="info" class="h-100 verification-code" @click="checkPhone">
                          获取短信码
                        </b-button>
                        <b-modal id="register" v-model="isShow" title="获取验证码" title-class="font-18" centered
                          class="person-modal" hide-footer style="width: 395px">
                          <div class="input-group" style="height: 45px">
                            <div class="input-group-prepend">
                              <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                                  src="@/assets/images/person/icon/security.png" alt="" /></span>
                            </div>
                            <el-input id="code" type="text" placeholder="请输入验证码"
                              aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100"
                              v-model="verification" />
                            <img :src="imgCode.img" class="check-img" @click="getImageCode">
                          </div>
                          <div class="w-100 mt-2">
                            <button type="button" class="btn btn-info change-btn" @click="checkImagecode">
                              获取短信验证码
                            </button>
                          </div>
                        </b-modal>
                      </div>
                    </div>
                  </el-form-item>
                  <div class="login-btn">
                    <button type="button" class="btn btn-info w-100" @click="checkRegister">
                      点击申请注册账号
                    </button>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div class="login-footer text-center mt-4">
            版权所有：{{baseConfig.WebsiteName}}
<!--            © {{baseConfig.SystemYear}} Rights Reserved-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
